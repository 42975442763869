/* eslint-disable no-useless-concat */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { v4 as uuidv4 } from 'uuid';
import ReCAPTCHA from 'react-google-recaptcha-enterprise';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { useNegotiation } from '~/context/negotiationV2';
import useRecaptchaMiAjuda from '~/hooks/useRecaptcha';
import { Status, LoadingStatus } from './components/Status';
import Home from './Home';
import FlowV2 from './FlowV2';
import NonNegotiable from './NonNegotiable';

const NegotiationCampaign = () => {
  const myRef = useRef(null);
  const reCaptchaRef = useRef(null);
  const [webView, setWebView] = useState(false);
  const [contaOnline, setContaOnline] = useState(false);
  const { toogles } = useSelector(state => state.featuretoggle);
  const [, setCaptchaState] = useState();
  const [, setCaptchaStateStatus] = useState();
  const [, setCaptchaStateExpired] = useState();

  const {
    showFlow,
    contracts,
    setClientData,
    clientData,
    loadingNegotiation,
    concatCPF,
    loadingText,
    showAlertStatus,
    negotiationData,
    setNegotiationData,
    setShowFlow,
    setDuplicateData,
    setHistoryPages,
    duplicateData,
    nonNegotiableContracts,
    setTokenDynamics,
  } = useNegotiation();

  const { handleReCaptchaVerify, captchaKey } = useRecaptchaMiAjuda('miajuda');

  const queryParams = new URLSearchParams(window.location.search);

  const handleReCaptchaVerifySubmit = () => {
    return reCaptchaRef.current.executeAsync().then(value => value);
  };

  const fetchContracts = (token, documentNumber, birthDate, isNewClient) => {
    setTokenDynamics(uuidv4());
    contracts({
      token,
      doc: documentNumber,
      birthD: birthDate,
      isNewClient,
    });
  };

  const fetchData = useCallback(async (documentNumber, birthDate) => {
    // Valida se os dados de CPF e Data de nascimento são diferentes do anterior já pesquisado
    const isNewClient =
      documentNumber !== clientData.document ||
      birthDate !== clientData.birthDate;
    const isMutipleContracts = negotiationData?.contracts.length > 1;

    // Valida se há propostas já carregadas e manda para a tela de acordo com o tipo de informação que tiver carregada
    if (
      (negotiationData?.contracts.length === 1 &&
        negotiationData?.eligibility &&
        !isNewClient) ||
      ((isMutipleContracts || !isEmpty(duplicateData)) && !isNewClient)
    ) {
      if (duplicateData && !isMutipleContracts) {
        setHistoryPages(['duplicate']);
      } else if (!duplicateData && negotiationData?.eligibility) {
        setHistoryPages(['eligibility']);
      } else if (!duplicateData && isMutipleContracts) {
        setHistoryPages(['contracts']);
      }
      setShowFlow(true);
      return;
    }
    // Limpa os campos de negotiationData e duplicateData para o novo cliente
    if (isNewClient) {
      setNegotiationData();
      setDuplicateData();
    }
    // Seta o valor digitado no input
    if (documentNumber && birthDate) {
      setClientData({
        ...clientData,
        document: documentNumber,
        birthDate,
      });
    }

    // Função que retorna o token captcha para a request
    if (!toogles.isEnabledRecaptchaEnterprise) {
      handleReCaptchaVerify()
        .then(token =>
          fetchContracts(token, documentNumber, birthDate, isNewClient)
        )
        .catch(err => {
          console.log(err);
        });
    } else {
      handleReCaptchaVerifySubmit()
        .then(token =>
          fetchContracts(token, documentNumber, birthDate, isNewClient)
        )
        .catch(err => {
          console.log(err);
        });
    }
  });

  // Valida os Params para já carregar as propostas dos clientes quando
  // redirecionados de lugares logados App e conta online
  const redirectPF = !!queryParams.get('pf');
  const webViewParam = !!queryParams.get('webView');
  const urlParamsVerify = () => {
    const document = queryParams.get('documentNumber');
    const birthDate = queryParams.get('birthDate');
    const utm = queryParams.get('utm_term');
    if (document && birthDate) {
      setClientData({
        ...clientData,
        document: concatCPF(document),
        birthDate,
      });
    }

    if (utm === 'Conta-Online' || redirectPF) setContaOnline(true);
    // Seta essa variável para remover o footer e header quando vir da
    // webView do app e não quebrar o layout da webview
    setWebView(webViewParam);
  };

  const handleChange = value => {
    setCaptchaState({ value });
    if (value === null) setCaptchaStateExpired({ expired: 'true' });
  };

  const asyncScriptOnLoad = () => {
    setCaptchaStateStatus({ callback: 'called!', recaptchaLoaded: true });
  };

  useEffect(() => {
    urlParamsVerify();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Limpa a URL após redirects
  useEffect(() => {
    if (webView || contaOnline) {
      if (!isEmpty(negotiationData)) {
        window.history.replaceState(
          {},
          window.document.title,
          '/campanha-quitacao'
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [negotiationData]);

  // Auto Request dos redirects
  useEffect(() => {
    if (
      (webView || contaOnline) &&
      clientData?.document &&
      clientData?.birthDate
    )
      fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientData?.document, clientData?.birthDate, webView, contaOnline]);

  const onPreLoading = () => {
    if (!isEmpty(clientData.document, clientData.birthDate)) return false;
    return redirectPF || webViewParam;
  };

  const loading =
    toogles.isEnabledPreLoading && (redirectPF || webViewParam)
      ? onPreLoading()
      : loadingNegotiation;

  return (
    <div ref={myRef}>
      {loading ? (
        <LoadingStatus text={loadingText} />
      ) : !isEmpty(showAlertStatus) ? (
        <Status status={showAlertStatus} />
      ) : nonNegotiableContracts?.has ? (
        <NonNegotiable />
      ) : showFlow ? (
        <FlowV2 />
      ) : (
        <>
          <Home fetchData={fetchData} />
          {toogles.isEnabledMiajudaCaptcha &&
            toogles.isEnabledRecaptchaEnterprise && (
              <ReCAPTCHA
                style={{ display: 'inline-block' }}
                theme="dark"
                size="invisible"
                ref={reCaptchaRef}
                sitekey={captchaKey}
                onChange={handleChange}
                asyncScriptOnLoad={asyncScriptOnLoad}
              />
            )}
        </>
      )}
    </div>
  );
};

export default () => {
  const { toogles } = useSelector(state => state.featuretoggle);
  const { captchaKey } = useRecaptchaMiAjuda('miajuda');

  if (!toogles.isEnabledMiajudaCaptcha) return <NegotiationCampaign />;

  return (
    <GoogleReCaptchaProvider reCaptchaKey={captchaKey}>
      <NegotiationCampaign />
    </GoogleReCaptchaProvider>
  );
};
