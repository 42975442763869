export const mockData = [
  {
    id: 1,
    name: 'Cartão de crédito',
    children: [
      {
        name: 'Como encontrar o extrato do meu cartão?',
        answer:
          'No aplicativo Midway, acesse a área de cartões ou clique em seu cartão na página inicial. Acesse o extrato do cartão através das últimas movimentações ou clicando em “Minhas faturas”. No aplicativo Riachuelo, acesse a área de cartões e encontre o extrato em últimas movimentações ou clicando em “Minhas faturas”. Não se esqueça de adicionar o seu cartão para que essa função esteja disponível!',
      },
      {
        name: 'Como pagar a fatura do Cartão Riachuelo?',
        answer:
          'No site e aplicativo Midway, informando CPF e data de nascimento, você pode gerar e copiar o código de barras para pagamento da fatura fechada; No aplicativo Riachuelo, realizando o vínculo do cartão, você pode gerar o boleto para pagamento da fatura fechada. Na Loja Riachuelo mais próxima, informando o CPF ou usando o cartão, você pode pagar a fatura no caixa ou terminal de autoatendimento.',
      },
      {
        name: 'Como parcelar a fatura do Cartão Riachuelo?',
        answer:
          'Parcelar a fatura do seu Cartão Riachuelo é fácil! Entre na área de cartões nos aplicativos da Midway ou Riachuelo. Ao acessar a sua fatura, você pode escolher a opção de parcelamento para efetuar o pagamento. Também é possível fazer o parcelamento em qualquer Loja Riachuelo.',
      },
      {
        name: 'Como alterar o vencimento da minha fatura?',
        answer:
          'Você pode solicitar a alteração do vencimento da sua fatura em qualquer Loja Riachuelo ou ligando em nossa central de atendimento.',
      },
    ],
  },
  {
    id: 2,
    name: 'Pix',
    children: [
      {
        name: 'Como fazer Pix?',
        answer:
          'No aplicativo da sua Conta Digital Midway, selecione a opção Pix em pagamentos e em seguida digite a chave ou os dados da conta de quem vai receber. Continue normalmente a transação inserindo o valor e data de transferência.',
      },
      {
        name: 'Não consigo fazer o Pix ou está demorando para cair em outra conta, e agora?',
        answer:
          "Em situações normais o Pix é instantâneo e você consegue fazer transações em segundos, porém podem ocorrer alguns erros durante a tentativa de pagar ou receber um Pix.\n\nChave Pix inválida ou inexistente: Verifique a chave inserida durante a transação, digite uma chave válida e tente novamente.\n\nProblema com QR Code e Pix copia e cola: Solicite um novo código Pix e tente novamente.\n\nO Token pode estar desabilitado: Habilite o token clicando em 'habilitar token' na tela inicial do aplicativo Midway e faça o Pix novamente.\n\nAplicativo desatualizado: Atualize para a última versão do aplicativo Midway e tente novamente.\n\nO Pix pode estar com uma instabilidade momentânea: Aguarde alguns instantes e tente novamente.\n\nSe a sua conta foi aberta em menos de 24h e as transações ainda estão bloqueadas: Aguarde o prazo de análise da conta e tente novamente.",
      },
      {
        name: 'Como criar uma chave Pix?',
        answer:
          "No aplicativo da sua Conta Digital Midway, clique no atalho Pix no menu inicial do aplicativo. Clique em “Chaves Pix” e depois em “Cadastrar nova chave”. É possível cadastrar até 4 tipos de chaves Pix: CPF/CNPJ, celular, e-mail ou chave aleatória.\n\nPara encontrar suas chaves, é só abrir o atalho Pix ao entrar no aplicativo e as informações estarão no botão superior. Você também pode alterar a chave que desejar clicando em 'Chaves Pix'.",
      },
      {
        name: 'Como acessar o comprovante detalhado do Pix?',
        answer:
          "Clique no atalho ”Pix” no menu inicial do aplicativo, clique em “Extrato Pix” e depois na transação realizada. Outra forma de consultar o comprovante do Pix é acessar o Extrato da Conta no botão 'Ver extrato” ao lado do saldo na tela inicial do aplicativo. Ao clicar na transação, você poderá visualizar e compartilhar o comprovante.",
      },
    ],
  },
  {
    id: 3,
    name: 'Cartão de débito',
    children: [
      {
        name: 'Como solicitar meu cartão de débito?',
        answer:
          'Para solicitar o seu cartão de débito, é necessário ter uma Conta digital Midway. Com a conta criada, na página inicial do aplicativo, é possível solicitar o seu cartão após realizar um depósito a partir de R$ 20,00 em sua nova conta. Lembre-se, o valor depositado ficará disponível para usar como quiser.',
      },
      {
        name: 'Como desbloquear meu cartão?',
        answer:
          'Você pode desbloquear o seu cartão no aplicativo Midway. Ao acessar a página inicial, quando o seu cartão for entregue, aparecerá a opção de desbloqueio! Siga o passo-a-passo e desbloqueie o seu novo cartão de débito!',
      },
      {
        name: 'Qual o prazo de entrega do meu cartão?',
        answer:
          "O prazo de entrega é de até 7 dias úteis e você pode acompanhar o envio no aplicativo Midway. Ao acessar a página inicial, clique na opção 'Acompanhar o envio' e encontre mais informações sobre a entrega do cartão.",
      },
      {
        name: 'Perdi o meu cartão, foi roubado ou extraviado, e agora?',
        answer:
          "Solicite o bloqueio do cartão e emissão de uma nova via através da Central de Atendimento. Você também pode bloquear seu cartão enviando um e-mail para online@riachuelo.com.br com o assunto 'Bloqueio Perda ou Roubo'. No corpo da mensagem, informe o nome completo e o CPF do titular do cartão, assim como o motivo do bloqueio.",
      },
    ],
  },
  {
    id: 4,
    name: 'Conta Digital',
    children: [
      {
        name: 'Como abrir uma Conta Digital Midway?',
        answer:
          'Para abrir uma Conta Digital Midway, é simples! Baixe o aplicativo Midway (disponível para Android e iOS), abra o aplicativo em seu dispositivo, insira o seu CPF e faça o cadastro inserindo algumas informações solicitadas. Após realizar todo o processo, sua conta será ativada e você poderá aproveitar todos os benefícios como: Pix ilimitado, Depósito e Saque em Lojas Riachuelo, pagamentos de boletos e ter o seu saldo com rendimento maior que a poupança!',
      },
      {
        name: 'Como ter uma Conta Midway Completa?',
        answer:
          "No aplicativo Midway, acesse seu perfil através da página inicial. Clique em 'Complete o seu cadastro' e siga as instruções. Pronto! Agora você possui uma Conta Digital Midway completa que possui mais vantagens com ofertas personalizadas.",
      },
      {
        name: 'Como compartilhar o extrato?',
        answer:
          "Para enviar o extrato, acesse sua conta e clique em 'Ver extrato'. Em seguida escolha o atalho 'Compartilhar extrato' e selecione o mês que deseja consultar. Agora você pode conferir ou compartilhar o documento referente ao mês escolhido.",
      },
      {
        name: 'Como fazer um depósito na minha conta?',
        answer:
          "Acesse a sua conta e clique no atalho 'Depósito'. Em seguida, escolha a opção desejada para fazer o depósito e selecione o valor. Em caso de depósito via Pix, é necessário ter uma chave cadastrada. Ao fazer um depósito para sua conta, o seu dinheiro não fica parado e rende automaticamente 103% do CDI.",
      },
    ],
  },
  {
    id: 5,
    name: 'Empréstimo',
    children: [
      {
        name: 'Como contratar um Empréstimo Pessoal?',
        answer:
          'Você pode contratar um Empréstimo Pessoal através do aplicativo Midway, em nossa central de atendimento ou em qualquer Loja Riachuelo. Com o crédito pré-aprovado, a contratação é muito rápida! O valor do seu Empréstimo Pessoal pode ser usado para qualquer finalidade - como pagamento de contas, fazer um curso ou reforma.',
      },
      {
        name: 'Como pagar a parcela do meu Empréstimo?',
        answer:
          'No aplicativo Midway, você pode acessar seus contratos, ver as parcelas em aberto e realizar o pagamento gerando um boleto. Se o seu pagamento é via débito automático, o pagamento é automático no dia do vencimento da sua parcela, basta ter saldo correspondente em sua conta; Em qualquer Loja Riachuelo com carnê ou informando o seu CPF; No site Midway, informando seu CPF e data de nascimento.',
      },
      {
        name: 'Como fazer a antecipação do FGTS?',
        answer:
          "Habilite a modalidade Saque-Aniversário no aplicativo FGTS. Você deve aderir ao Saque-Aniversário do FGTS, ler e aceitar os termos e condições. Em seguida, clique em 'autorizar bancos a consultarem seu FGTS', selecione a opção 'Empréstimo Saque-Aniversário' e selecione a Midway. No aplicativo Midway, clique no atalho 'Empréstimo' na página inicial. Contrate o adiantamento e receba em instantes!",
      },
      {
        name: 'Como negociar minha dívida?',
        answer:
          'Acesse o site Mi Ajuda, informe o seu CPF e data de nascimento e consulte as opções de acordo disponíveis para negociar a sua dívida.',
      },
    ],
  },
  {
    id: 6,
    name: 'Seguros e Assistências',
    children: [
      {
        name: 'Quais seguros são oferecidos pela Midway e como contratar?',
        answer:
          'A Midway oferece os Seguros: Bolsa, Pix e Cartão Protegido; Residencial; Celular; Conta Paga; Acidentes pessoais; e Proteção Financeira. Todas as assistências podem ser contratados nas Lojas Riachuelo, no aplicativo Midway e por meio dos telefones das centrais de atendimento.',
      },
      {
        name: 'Como acionar meu seguro?',
        answer:
          'Você deve entrar em contato diretamente com a seguradora para obter orientações específicas sobre como acionar seu seguro.',
      },
      {
        name: 'Quais assistências são oferecidas pela Midway e como contratar?',
        answer:
          'A Midway oferece as Assistências para a sua Residência, Automóvel e Moto em parceria com a empresa CDF e também conta com serviços para a sua saúde! Todas as assistências podem ser contratadas nas Lojas Riachuelo, no aplicativo Midway e por meio dos telefones das centrais de atendimento.',
      },
      {
        name: 'Como acionar minha Assistência?',
        answer:
          'Você pode ligar para o número 0800 704 5025. A nossa central está a disposição 24 horas por dia e 7 dias na semana para realizar o atendimento.',
      },
    ],
  },
];
